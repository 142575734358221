.App {
  text-align: center;
  font-family: 'Nunito Sans';
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 1em;
}
.App-header {
  margin: 1em auto;
  max-width: 600px;
  width: 100%;
}
.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.9em;
}
.App-header h1 {
  margin: 0 auto;
  font-size: 1.3em;
}


.App-logo {
  pointer-events: none;
  max-width: 250px;
  width: 100%;
  margin: 2rem auto;
}
.newsletterForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 350px;
  width: 100%;
  margin: 2rem auto;
}
.newsletterForm label{
  text-align: left;
  
} 
.newsletterForm label p {
  margin-top: 0.6em;
  margin-bottom: 0;
}
.newsletterForm input {
  box-shadow: none!important;
  width: 100%;
  box-sizing: border-box;
  max-width: 350px;
  margin: 0.4rem auto;
  resize: none;
  padding: 0.875rem 0.725rem;
  font-size: 13.5px;
  border-radius: 10px;
  border: 2px solid transparent;
  background-color: #fefefe!important;
}

.newsletterForm input[type="submit"] {
  color: #000;
  background-color: #d6efa4 !important;
  border: 2px solid transparent;
  font-weight: 600;
  text-transform: uppercase;
  display: grid;
  place-content: center;
}


.footer{
  max-width: 1200px;
}
.footer-img {
  max-height: 400px;
}